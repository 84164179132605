import { Space, Typography } from "antd"
import { Popup } from "react-map-gl"

const ConnectionResults = ({ step, longitude, latitude, results }: { step: number, longitude: any, latitude: any, results: any }) => {
    const formatCurrency = (value: any) => {
        return `€${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };
    return (

        <Popup anchor="left" longitude={longitude} latitude={latitude}
        >
            <Space direction="vertical">
                <div><span>Distance to nearest point (km) <Typography.Title level={5}>{results.distance_to_nearest_point_in_km}</Typography.Title></span></div>
                <div><span>Installation cost<Typography.Title level={5}>{results.installation_cost}</Typography.Title></span></div>
                <div><span>Per mva cost<Typography.Title level={5}>{results.per_mva_cost}</Typography.Title></span></div>
                <div><span>Supplementary cost<Typography.Title level={5}>{results.supplementary_cost}</Typography.Title></span></div>
                <div><span>Total cost<Typography.Title level={5}>{formatCurrency(results.total_cost)}</Typography.Title></span></div>
            </Space>
        </Popup>
    )
}

export default ConnectionResults