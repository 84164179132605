import { useEffect, useState } from "react";
import { IconLayer } from "deck.gl";
import MapPin from "../../assets/Map_pin.png";
// import { MVTLayer } from "@deck.gl/geo-layers/typed"


import Deck from "../common/Deck";
import MapDataExplorerSection from "./MapDataExplorerSection";
import NotificationSection from "./NotificationSection";
import TermsAndConditions from "../../pages/TermsAndConditions";
import { isEmpty } from "lodash";
import { FlyToInterpolator } from "react-map-gl";

const la = JSON.parse(
    window.sessionStorage.getItem("local_authority") ?? "{}"
);

const initialViewState = {
    latitude: la?.latitude,
    longitude: la?.longitude,
    zoom: 10.5,
    minZoom: 2,
    maxZoom: 24,
    bearing: 0,
    pitch: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
};

export const ESBConnectionUI = () => {
    const [pins, setPins] = useState<any[]>([]);
    const [step, setStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [costResults, setCostResults] = useState<any>({})

    useEffect(() => {
        if (inputValue && isEmpty(pins)) {
            setStep(1);
        } else if (inputValue && !isEmpty(pins)) {
            setStep(2);
        }
    }, [inputValue, pins]);

    const handleIconClick = (info: any) => {
        if (info && info.object) {
            setStep((prevStep) => prevStep + 1);
        }
    };


    const pinLayer = new IconLayer({
        id: "icon-layer",
        data: pins,
        pickable: true,
        iconAtlas: MapPin,
        iconMapping: {
            marker: { x: 0, y: 0, width: 88, height: 80, mask: false },
        },
        getIcon: () => "marker",
        getPosition: (d) => d.coordinates,
        getSize: 40,
        getColor: [0, 0, 0],
        onClick: handleIconClick,
    });

    // const mvtLayer = new MVTLayer({
    //     id: "la-boundary",
    //     data: "https://d2toru5i4bimlb.cloudfront.net/ukpn/cbe_primary_data_with_headroom/{z}/{x}/{y}",
    //     filled: true,
    //     getLineColor: [60, 178, 208],
    //     getFillColor: (d: any) => {
    //         const val = 2
    //         if (val < 5) {
    //             return [255, 0, 0];
    //         } else if (val < 10) {
    //             return [255, 255, 0];
    //         } else if (val < 12) {
    //             return [0, 255, 255];
    //         } else {
    //             return [0, 0, 255];
    //         }
    //     },
    //     stroked: true,
    //     getLineWidth: 10,
    //     lineWidthScale: 5,
    //     lineWidthMinPixels: 1,
    //     lineWidthMaxPixels: 10,
    //     opacity: 1,
    //     // onClick: (e: any) => console.log(e),
    //     pickable: true,
    // });


    if (loading) {
        return <NotificationSection step={step} />;
    }

    return (
        <div>
            <MapDataExplorerSection
                step={step}
                setStep={setStep}
                pins={pins}
                loading={loading}
                setLoading={setLoading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setCostResults={setCostResults}
            />
            <Deck
                pins={pins}
                setPins={setPins}
                step={step}
                customLayers={pinLayer}
                viewStateProp={initialViewState}
                results={costResults}
            />
            <NotificationSection step={step} setStep={setStep} setPins={setPins} />
            <TermsAndConditions />
        </div>
    );
};

export default ESBConnectionUI;
