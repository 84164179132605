//@ts-ignore
import DeckGl from "@deck.gl/react";

import {
  StaticMap,
  _MapContext as MapContext,
} from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { StateTypes } from "../../model/data";
import { layerState, useLayerStore } from "../../stores/LayerStore";
import { useEffect } from "react";
import { changeViewState } from "../../model";
import { isEmpty } from "lodash";
import ConnectionResults from "../esb-connection/ConnectionResults";


const Deck = ({ pins, setPins, step, customLayers, initialViewState, width, height, results }: any) => {

  const mapStyle = useSelector((s: StateTypes) => s.deckgl.mapStyle);
  const { layers, layerFilter, resetLayers } = useLayerStore(
    (state: layerState) => ({
      layers: state.layers,
      layerFilter: state.layerFilter,
      resetLayers: state.resetLayers,
    })
  );

  const dispatch = useDispatch();
  const la = JSON.parse(
    window.sessionStorage.getItem("local_authority") ?? "{}"
  );

  const { viewState } = useSelector((state: StateTypes) => state.deckgl);

  useEffect(() => {
    return () => resetLayers();
  }, [resetLayers]);

  const handleMapClick = (event: any) => {
    const [longitude, latitude] = event.coordinate
    setPins([{ coordinates: [longitude, latitude] }]);
  };

  return (
    <div style={{ position: "relative" }}>
      <DeckGl
        layers={[...layers, customLayers]}
        initialViewState={viewState ? { ...viewState } : { ...initialViewState }}
        width={width ?? "100%"}
        height={height ?? "100vh"}
        controller={true}
        //@ts-ignore
        ContextProvider={MapContext.Provider}
        getCursor={({ isDragging, isHovering }: any) =>
          isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
        }
        layerFilter={layerFilter}
        onClick={handleMapClick}
        onViewStateChange={(e: any) => {
          const { latitude, longitude, zoom, bearing, pitch } = e.viewState;
          const intZoom = parseInt(zoom);

          dispatch(
            changeViewState({ latitude, longitude, zoom, bearing, pitch, intZoom })
          );
        }}
      >
        <StaticMap
          mapStyle={mapStyle.url}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        >
          <div
            style={{ position: "relative", margin: 20 }}
          >
            {(!isEmpty(pins) && step === 4) ? (
              <ConnectionResults
                step={step}
                latitude={pins[0]?.coordinates[1] ?? la?.latitude}
                longitude={pins[0]?.coordinates[0] ?? la.longitude}
                results={results}
              />) : null}
          </div>
        </StaticMap>
      </DeckGl>
    </div >
  );
};

export default Deck;
