import "./App.css";
import "./components/components.css";
import "./components/media@1240.css";
import { Layout, Spin } from "antd";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useSelector } from "react-redux";
import { StateTypes } from "./model/data";
import ESBConnectionUI from "./components/esb-connection/ESBConnectionUI";
import BackgroundImage from "../src/assets/default-esb-background.png"


const NoPage = lazy(() => import("./pages/NoPage"));
const Auth = lazy(() => import("./pages/Authentication/Auth"));
const AuthIndex = lazy(() => import("./pages/Authentication/AuthIndex"));


function App() {

  const userLoggedIn = useSelector((state: StateTypes) => state.user.loggedIn);

  return (
    <Router>
      <Suspense
        fallback={
          <Spin
            size="large"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        }
      >
        <Switch>
          <Route path="/" exact component={Auth} />
          <Route path="/auth/:page" exact component={AuthIndex} />
          <>
            {userLoggedIn && <TermsAndConditions />}
            <Layout>
              <Layout style={{
                height: "100vh", width: "100vw", backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover",
                backgroundPosition: "center",
              }} >
                <Switch>
                  <PrivateRoute
                    path="/la/:laName/home"
                    exact
                    component={ESBConnectionUI}
                    feature={true}
                  />
                  <Route path="*" component={NoPage} />
                </Switch>
              </Layout>
            </Layout>
          </>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;