import { notification } from "antd";
import { AxiosInstance } from "axios";
import { useMutation, useQuery } from "react-query";

const handleApiError = (error: any, customMessage?: string) => {
  // Display notification for the error
  notification.error({
    message: "API Error",
    placement: "bottomRight",
    description:
      customMessage ||
      "There was an error with the API. Please try again later.",
  });

  // You can also log the error to the console if needed
  console.error("API Error:", error);

  // Rethrow the error to propagate it to the caller
  throw error;
};

const getPresignedUrl = async (axiosInstance: AxiosInstance) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_TERMS_GET_PRESIGNED_URL}`
    );
    return data?.data;
  } catch (error) {
    handleApiError(error, "We had a problem fetching presign url for terms.");
  }
};

const getIsTermsAcceptedFlag = async (axiosInstance: AxiosInstance) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_TERMS_API}`
    );

    return data?.data;
  } catch (error) {
    handleApiError(error, "We had a problem fetching  conditions");
  }
};

const getForecastAndPlansData = async (axiosInstance: AxiosInstance) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_STATIC_TEXT_API}${"Share Your Plans"}`
    );
    return data?.data;
  } catch (error) {
    handleApiError(error, "Could not get synopsis details");
  }
};

export const IsTermsAndConditionsAccepted = (
  axiosInstance: AxiosInstance,
  enable_api?: boolean
) => {
  return useQuery({
    queryKey: "isTermsAccepted",
    queryFn: () => getIsTermsAcceptedFlag(axiosInstance),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    staleTime: Infinity,
    enabled: enable_api,
  });
};

export const GetPresignedUrlData = (
  axiosInstance: AxiosInstance,
  enable_api?: boolean
) => {
  return useQuery({
    queryKey: "getPresignedUrl",
    queryFn: () => getPresignedUrl(axiosInstance),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    staleTime: Infinity,
    enabled: enable_api,
  });
};

const fetchDsoFeedback = async (
  axiosInstance: AxiosInstance,
  entity_type: string,
  entity_id: string | number,
  selectedLA: string
) => {
  const dno = process.env.REACT_APP_DNO_NAME as string;

  try {
    const URL =
      entity_type === "projects"
        ? `/pj/${dno}/la/${selectedLA}/projects/`
        : `/sm/${dno}/la/${selectedLA}/scenarios/`;
    const { data } = await axiosInstance.get(
      `${URL}${entity_id}/feedback_data/`
    );
    return data?.data;
  } catch (error) {
    handleApiError(error, `Could not get ${entity_type} list.`);
  }
};

export const ForecastAndPlansData = (axiosInstance: AxiosInstance) => {
  return useQuery({
    queryKey: "forecastAndPlans",
    queryFn: () => getForecastAndPlansData(axiosInstance),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    staleTime: Infinity,
  });
};

const fetchShareScenarioStatus = async (
  axiosInstance: AxiosInstance,
  scenario_id: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_SCENARIOS_API}${scenario_id}/`
    );
    return data?.data;
  } catch (error) {
    handleApiError(error, "Could not get scenario status");
  }
};

export const GetShareScenarioStatus = (
  axiosInstance: AxiosInstance,
  scenario_id: string,
  enable_api?: boolean
) => {
  return useQuery({
    queryKey: ["scenario-share-status", scenario_id],
    queryFn: () => fetchShareScenarioStatus(axiosInstance, scenario_id),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    staleTime: Infinity,
    enabled: enable_api,
  });
};

const fetchDfesScenarioInfo = async (axiosInstance: AxiosInstance) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_STATIC_TEXT_API}${"DFES"}`
    );

    return data?.data;
  } catch (error) {
    handleApiError(error, "Could not get dfes scenario info");
  }
};

const fetchCommonFeatureDetails = async (axiosInstance: AxiosInstance) => {
  try {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_LA_USER_DETAILS}`
    );
    window.Intercom("update", {
      scenarios_created: data?.data?.user_data_list?.number_of_scenarios,
      projects_created: data?.data?.user_data_list?.number_of_projects,
      datastories_created: data?.data?.user_data_list?.number_of_datastories,
      consultations_created:
        data?.data?.user_data_list?.number_of_consultations,
    });
    return data?.data;
  } catch (error) {
    handleApiError(error, "Could not get details");
  }
};

export const GetDfesScenarioInfo = (axiosInstance: AxiosInstance) => {
  return useQuery({
    queryKey: ["dfes-scenario-info"],
    queryFn: () => fetchDfesScenarioInfo(axiosInstance),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    staleTime: Infinity,
    enabled: true,
  });
};

export const GetDsoFeedback = (
  axiosInstance: AxiosInstance,
  entity_type: string,
  entity_id: string | number,
  selectedLA: string,
  enable_api?: boolean
) => {
  return useQuery({
    queryKey: ["get-dso-feedback"],
    queryFn: () =>
      fetchDsoFeedback(axiosInstance, entity_type, entity_id, selectedLA),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    enabled: enable_api,
  });
};

export const useLogoutMutation = (axiosInstance: AxiosInstance) => {
  return useMutation(async (postData: any) => {
    try {
      const data = await axiosInstance.post(
        `${process.env.REACT_APP_LOGOUT}`,
        postData
      );
      return data;
    } catch (error) {
      handleApiError(error, "Error logging out");
    }
  });
};

// intercom settings update

export const CommonFeatureDetails = (axiosInstance: AxiosInstance) => {
  return useQuery({
    queryKey: ["common-feature"],
    queryFn: () => fetchCommonFeatureDetails(axiosInstance),
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 1000,
    staleTime: Infinity,
    enabled: true,
  });
};

export const useEsoResultsMutation = (axiosInstance: AxiosInstance) => {
  console.log(`${process.env.REACT_APP_BASE_URL_2}`)
  return useMutation({
    mutationFn: async (payload: any) => {
      try {
        const { data } = await axiosInstance.post(
          `${process.env.REACT_APP_BASE_URL_2}/cco/esb/la/drg/nearestcable/`,
          payload
        );
        console.log(data?.data)
        return data?.data;
      } catch (error) {
        handleApiError(error, "Error in getting results.");
      }
    },
  });
};

