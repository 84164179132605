import { isEmpty, isUndefined, omit } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";

export interface layerState {
  layers: any[];
  configs: any;
  processedData: any;
  selectedIds: string[];
  polygonInfo: any;
  columnMeta: any;
  layerFilter: any;
  addLayers: (layer: any) => void;
  saveConfigs: (config: any) => void;
  editConfigs: (config: any, id: any) => void;
  saveProcessedData: (data: any) => void;
  editProcessedData: (data: any, id: any) => void;
  saveSelectedIds: (ids: string[]) => void;
  savePolygonInfo: (polygonInfo: any) => void;
  saveColumnMeta: (columnMeta: any) => void;
  resetLayers: () => void;
  setLayerFilter: (layerFilter: any) => void;
  resetLayerFilter: () => void;
}

const HEAVY_ACTIONS = ["addLayers", "saveProcessedData", "editProcessedData"];

const store = (set: any) => ({
  layers: [],
  configs: {},
  processedData: {},
  selectedIds: [],
  polygonInfo: {},
  columnMeta: {},
  layerFilter: ({ layer, viewport }: any) => {
    return layer;
  },
  addLayers: (layer: any) => {
    set(() => ({ layers: layer }), false, "addlayers");
  },
  resetLayers: () => {
    set(() => ({ layers: [] }), false, "resetlayers");
  },
  saveConfigs: (config: any) => {
    set((state: layerState) => ({
      configs: { ...state.configs, ...config },
    }));
  },
  editConfigs: (config: any, id: any) => {
    set((state: layerState) => {
      const newConfigs = { ...state.configs };
      newConfigs[id] = { ...newConfigs[id], ...config };
      return { configs: newConfigs };
    });
  },
  saveProcessedData: (data: any) => {
    set((state: layerState) => ({
      processedData: { ...state.processedData, ...data },
    }));
  },
  editProcessedData: (data: any, id: any) => {
    set((state: layerState) => {
      const newProcessedData = { ...state.processedData };
      newProcessedData[id] = { ...newProcessedData[id], ...data };
      return { processedData: newProcessedData };
    });
  },
  saveSelectedIds: (ids: string[]) => {
    set(() => ({ selectedIds: ids }));
  },
  savePolygonInfo: (info: any) => {
    set((state: layerState) => {
      if (!isEmpty(info)) {
        const { object } = info;
        const updated_obj = omit(object, ["geometry", "key"]);
        const final_obj = Object.keys(updated_obj).reduce(
          (acc: any, key: any) => {
            if (key.includes("<sep>") && key.includes("<id>")) {
              const [column_name, rest] = key.split("<sep>");
              const [dataset_name, id] = rest.split("<id>");
              acc[column_name] = {
                value: updated_obj[key],
                description: !isUndefined(state.columnMeta[id]?.[column_name])
                  ? state.columnMeta[id]?.[column_name]
                  : "No description available",
                dataset_name,
                id,
              };
            } else {
              acc[key] = {
                value: updated_obj[key],
                description: !isUndefined(state.columnMeta[key])
                  ? state.columnMeta[key]
                  : "No description available",
                dataset_name: "",
                id: "",
              };
            }
            return acc;
          },
          {}
        );
        return {
          polygonInfo: {
            ...info,
            object: final_obj,
          },
        };
      } else {
        return { polygonInfo: info };
      }
    });
  },
  saveColumnMeta: ({ columnMeta }: any) => {
    set((state: layerState) => {
      const newColumnMeta = { ...state.columnMeta, ...columnMeta };
      return { columnMeta: newColumnMeta };
    });
  },
  setLayerFilter: (layerFilter: any) => {
    set(() => ({ layerFilter }));
  },
  resetLayerFilter: () => {
    set(() => ({ layerFilter: ({ layer, viewport }: any) => {
      return layer;
    } }));
  },
});

const options = {
  name: "Layer Store",
  enabled: process.env.NODE_ENV === "development",
  actionSanitizer: (action: any) => {
    switch (true) {
      case action.type.includes(HEAVY_ACTIONS):
        return typeof action.payload !== "undefined"
          ? { ...action, payload: "<<LONG_BLOB>>" }
          : { ...action, results: "<<LONG_BLOB>>" };
      default:
        return action;
    }
  },
  stateSanitizer: (state: any) => ({
    ...state,
    layers: "<<LONG_BLOB>>",
    processedData: "<<LONG_BLOB>>",
  }),
};

const useStore = create<layerState>()(devtools((set) => store(set), options));

export const useLayerStore = <U>(selector: (state: any) => U) => {
  return useStore(selector, shallow);
};

export const savePolygonInfoFunc = (info: any) =>
  useStore.getState().savePolygonInfo(info);