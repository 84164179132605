import {
  Card,
  Input,
  Modal,
  Spin,
  Typography,
  notification,
} from "antd";
import { useQuery } from "react-query";
import useAxios from "../../hooks/useAxios";
import { errorPrompt } from "./Prompt";
import { useDispatch, useSelector, batch } from "react-redux";
import { StateTypes } from "../../model/data";
import { setContinueWorking, setLaModalVisible } from "../../model";
import { useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { updateMasterPlanRole } from "../../model/user";

const SelectLa = () => {
  const visible = useSelector((s: StateTypes) => s.global.laModalVisible);
  const [la, setLa] = useState<any>(undefined);
  const [laLoading, setLaLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<any>("");
  const axios = useAxios();
  const dispatch = useDispatch();

  const getLa = async () => {
    return await axios.get(`${process.env.REACT_APP_GET_USER_LA}`);
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: "la",
    queryFn: getLa,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (isError) {
    errorPrompt({
      message: "Network Error!",
      description: "Could not get user la's",
    });
  }

  const LaList = useMemo(() => {
    return isEmpty(data)
      ? []
      : data?.data?.data?.active_la_list
        .filter((item: any) => item["area_type"] === "LA")
        .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
  }, [data]);

  const setLocalAuthority = async () => {
    if (!isEmpty(la)) {
      try {
        setLaLoading(true);
        const {
          data: { data },
        } = await axios.get(`/ls/${la.dno}/la/${la.name}/boundarydata/`);

        const {
          data: { data: res },
        } = await axios.get(`${process.env.REACT_APP_LA_USER_DETAILS}`);

        window.Intercom("update", {
          local_authority: la.description,
          la_code: la.name,
        });

        const cntWok = {
          consulation_exists: data.consulation_exists,
          project_exists: data.project_exists,
          scenario_exists: data.scenario_exists,
        };

        batch(() => {
          dispatch(updateMasterPlanRole(res.master_plan_role));
          dispatch(setContinueWorking(cntWok));
        });

        window.sessionStorage.setItem(
          "local_authority",
          JSON.stringify({ ...la, ...data })
        );
        setLaLoading(false);
        setTimeout(() => {
          window.location.replace(`/la/${la.name}/home`);
        }, 500);
      } catch (err) {
        errorPrompt({
          message: "Could not get LA boundary",
        });
        setLaLoading(false);
      }
    } else
      notification.warning({
        message: "Please select a local authority",
        description:
          "if you wish to continue, Please select a different local authority",
        placement: "bottomRight",
      });
  };

  const selected_la = useMemo(() => {
    if (isEmpty(la))
      return JSON.parse(
        window.sessionStorage.getItem("local_authority") ?? "{}"
      );
    else return la;
  }, [la]);

  return (
    <Modal
      title={
        <Typography.Title
          level={4}
          style={{
            textAlign: "start",
          }}
        >
          Select your region
        </Typography.Title>
      }
      className="selectLaModal"
      centered
      open={visible}
      closable={false}
      onCancel={() => {
        setLa(undefined);
        dispatch(setLaModalVisible(false));
      }}
      onOk={setLocalAuthority}
      okButtonProps={{
        type: "primary",
        style: {
          backgroundColor: isEmpty(selected_la) || LaList.length === 1 ? "#E0E0E0" : "var(--default-cta)",
          border: "none",
          width: 120
        },
        disabled: isEmpty(selected_la) || LaList.length === 1,
        loading: laLoading,
      }}
      cancelButtonProps={{
        type: "primary",
        style: {
          backgroundColor: "#e3e3e3",
          border: "1px solid #767676",
          width: 120,
          color: "#1e1e1e"
        },
      }}
      okText="Continue"
      width={350}
      bodyStyle={{
        maxHeight: 250,
        overflowY: "scroll",
        padding: "8px 24px",
      }}
      maskClosable={false}

    >

      {isLoading ? (
        <Spin style={{ width: "100%" }} />
      ) : (
        <div
        >
          <Input.Search
            placeholder="Search local authority..."
            allowClear
            onChange={({ target: { value } }) => setSearch(value)}
            style={{ marginBottom: 16 }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              gap: 12,
            }}
          >
            {LaList?.filter(
              (item: any) =>
                item.description
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.name.toLowerCase().includes(search.toLowerCase())
            ).map((item: any) => (
              <Card
                hoverable
                key={item.la_id}
                style={{
                  backgroundColor:
                    item.la_id === selected_la?.la_id
                      ? "var(--light-blue)"
                      : "var(--default-white)",
                  borderRadius: 4,
                }}
                onClick={() => setLa(item)}
                bodyStyle={{ padding: 8 }}
              >
                <Typography.Text>{item.description}</Typography.Text>
              </Card>
            ))}
          </div>

        </div>
      )}
    </Modal>
  );
};

export default SelectLa;