
const notificationStatus = ["Choose MVA quantity", "Place the pin on the map", "Click on “Estimate connection cost", "Estimating connection cost...", "Start again"]


const NotificationSection = ({ step, setStep, setPins }: any) => {
    const isResultUI = step === 4
    return (
        <div className='esb_notification'>
            <span onClick={() => { setStep(1); setPins([]); }} style={{ fontWeight: 600, cursor: isResultUI ? "pointer" : "none", textDecoration: isResultUI ? "underline" : "none" }}>{notificationStatus[step]}</span>
        </div >)
};

export default NotificationSection;